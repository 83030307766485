import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const GeorgiaOKeefe = () => {
  return (
    <Layout>
      <Head title="Georgia O'Keefe" />
      <h3>Georgia O'Keefe</h3>
      <p>
        <OutboundLink href="https://www.okeeffemuseum.org/">
          Museum in Santa Fe
        </OutboundLink>
      </p>
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659548796/artnow/georgia%20o%20keefe/georgiaokeefe1-min.jpg"
        alt="Georgia O'Keeefe painting"
      />
      {/* <img src="https://res.cloudinary.com/benjifri/image/upload/v1659548796/artnow/georgia%20o%20keefe/georgiaokeefe2-min.jpg" /> */}
    </Layout>
  );
};

export default GeorgiaOKeefe;
